
<template>
  <vx-card>
    <div class="page">
      <!-- HEADER -->
      <!-- <vs-checkbox v-model="watermark">ลายน้ำรับรองว่าตรวจสอบทุกรายการ</vs-checkbox> -->
      <!-- <div v-if="forwho === 'c'" class="customer_label">ลูกค้า</div>
      <div v-if="forwho === 's'" class="staff_label">ร้านค้า</div> -->
      <div
        id="facility-card"
        class="grid-layout-container alignment-block"
        style="font-family: SukhumvitSet-Medium; vertical-align:text-top ;width:100%;  padding: 30px; padding-top: 27px ; padding-bottom: 10px;"
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            style="font-size: 6 px !important;"
          >
            <!-- ชื่อผู้เช่า -->
            <vs-row class="pt-6" vs-w="12" style="margin-top:5px;">
              <vs-col
                class="theLabel"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="12"
                vs-sm="12"
                vs-xs="12"
                style="font-size: 1rem;"
              >
                ชื่อร้านส่งซัก: {{ wnfShop.wnfShopName }}
                <br />
                เบอร์ติดต่อ : {{ wnfShop.wnfShopPhone }}
                <br />
                ที่อยู่ : {{ wnfShop.wnfShopAddress  }}
                <br />
                <br />
                <div
                  style="width:100%;display:inline-block; font-size: 15px;"
                >ส่งวันที่ : {{ format_ddmmyyy(wnfOrder.header.sendingDate) }}</div>
                <br />
                <div
                  style="width:100%;display:inline-block; font-size: 15px;"
                >กำหนดรับคืน : {{ format_ddmmyyy(wnfOrder.header.receivingDate) }}</div>
              </vs-col>
            </vs-row>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
          >
            <vs-row vs-w="12" vs-type="flex" vs-align="flex-start">
              <!-- ใบเสร็จรับเงิน -->
              <vs-row vs-w="12" vs-type="flex" vs-align="flex-start">
                <vs-col
                  class="theLabel"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <div style="font-size: 24px; font-weight: bold;">ใบส่งชุดซัก/ซ่อม</div>
                </vs-col>
              </vs-row>
              <!-- เบอร์ติดต่อ -->
              <vs-row vs-w="12" style="margin-top: 5px;">
                <vs-col
                  class="theLabel"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  เลข Order &nbsp;
                  <span class="px-1" style="font-weight: light;">
                    {{
                    wnfOrder.header.orderNumber
                    }}
                  </span>
                </vs-col>
              </vs-row>
              <!-- วันที่ออก -->
              <vs-row vs-w="12" style="margin-top: 5px;">
                <vs-col
                  class="theLabel"
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                วันที่ออก &nbsp; {{ format_ddmmyyy(wnfOrder.header.createDateTime) }}
                </vs-col>
              </vs-row>
              <!-- Bracode value -->
              <vs-row vs-w="12" style="margin-top: 5px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="center"
                  vs-align="center"
                  vs-lg="12"
                  vs-sm="12"
                  vs-xs="12"
                >
                  <div>
                    <!-- <vue-barcode
                      style="width:100%;"
                      v-bind:value="orderNumber"
                      tag="img"
                    >ไม่มีบาร์โค้ด</vue-barcode> -->
                  </div>
                </vs-col>
              </vs-row>
            </vs-row>
          </vs-col>

          <vs-col
            class="pt-4"
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-start"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
          >
            <vs-row vs-w="12" vs-type="flex" vs-align="flex-end" vs-justify="flex-end">
              <img style="width: 150px;" :src="theLogo()" />
            </vs-row>
            <vs-row
              style=" text-align:right;  font-weight: bold; "
              vs-w="12"
              vs-type="flex"
              vs-align="flex-end"
              vs-justify="flex-end"
              class="forprint"
            >
            <!-- {{shopinfo.name}} {{ aBranch.branchName }} -->

            </vs-row>

            <vs-row
              style="text-align:right;"
              vs-w="12"
              vs-type="flex"
              vs-align="flex-end"
              vs-justify="flex-end"
              class="forprint"
            >
            <!-- {{ aBranch.address }} -->
            </vs-row>

            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="flex-end"
              vs-justify="flex-end"
              class="forprint"
            >
            <!-- โทร. {{ aBranch.phone }} -->
            </vs-row>
            <vs-row v-if="shopinfo.line !== null && shopinfo.line !==  undefined && shopinfo.line !==  ''" vs-w="12" vs-type="flex" vs-align="flex-end" vs-justify="flex-end">Line: {{shopinfo.line}} </vs-row>
            <vs-row
              vs-w="12"
              vs-type="flex"
              vs-align="flex-end"
              vs-justify="flex-end"
              class="forprint"
            >
            <!-- เปิดบริการ {{ aBranch.workingTime }} -->
            </vs-row>
          </vs-col>
        </vs-row>
      </div>
      <!-- <div
        v-if="watermark === true"
        style="position:absolute; margin-top:50px ; margin-left: 20%;width: 50%; z-index: 999;"
      >
        <img style=" width: 100%;" :src="alreadycheckedImage()" />
      </div> -->

      <!-- ตาราง -->
      <div>
        <vs-table hoverFlat stripe :data="wnfOrder.itemList" >
          <template slot="thead">
            <vs-th>รูปตัวอย่าง</vs-th>
            <vs-th style="width:200px;" sort-key="itemName">ชื่อสินค้า</vs-th>
            <vs-th sort-key="reservedQuantity">จำนวน</vs-th>
            <vs-th style="text-align:center;" >ข้อมูลออเดอร์</vs-th>
            <!-- <vs-th style="text-align:center;" >เงินประกัน</vs-th> -->
          </template>

          <template>
            <vs-tr class="table-font"  :key="indextr" v-for="(tr, indextr) in wnfOrder.itemList">
              <vs-td>
                <div
                  class="grid-layout-container alignment-block"
                  style="vertical-align:text-top ;width:100%;"
                >
                  <vs-row vs-w="12">
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="6"
                    >
                      <img
                        class="responsive rounded"
                        :src="getImagepath(tr.mainImage)"
                        alt="user-upload"
                        style="width:50px;"
                      />
                    </vs-col>
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      vs-lg="6"
                      vs-sm="6"
                      vs-xs="6"
                    >

                    </vs-col>
                  </vs-row>
                </div>
              </vs-td>

              <vs-td >
                {{ tr.name }}
                <br />

                <span style="font-size:9px;">({{ tr.code }})</span>
                <br />
                <!-- <i
                  v-if="getRemoved(indextr) === 'y'"
                  class="vs-icon notranslate icon-scale vs-button--icon material-icons null"
                  style="order: 0;text-align:left;  font-size:25px; color:red; cursor:pointer;"
                >cancel</i> -->
              </vs-td>

              <vs-td>
                {{
                  tr.reservedQuantity
                }}
              </vs-td>

              <vs-td>
                <span style="padding:14px 10px;">
                  {{

                  checkOrder(tr.orderNumber)

                  }}
                </span>
              </vs-td>


            </vs-tr>
          </template>
        </vs-table>
        <!-- <vs-row
          style="font-size:9px;"
          v-if="thepage > 1"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
        >หน้า {{ ind + 1 }}</vs-row> -->
        <!-- <div v-if="ind + 1 <= thepage - 1" class="pagebreak"></div> -->
      </div>

      <!-- <div v-if="signnextpage" class="pagebreak"></div> -->

      <!--------------------------------------------------------------------->
      <!--------------------------------------------------------------------->
      <!--------------------------------------------------------------------->

      <!--------------------------------------------------------------------->
    </div>
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
import VueBarcode from "vue-barcode";

import shapeFormat from "../../mixins/shapeFormat.js";

// import jsPDF from "jspdf";

// import html2canvas from "html2canvas";

const dict = {
  custom: {
    name: {
      required: "ยังไม่ได้ใส่ชื่อลูกค้า"
    }
  }
};

Validator.localize("en", dict);

export default {
  mixins: [shapeFormat],
  // props: {
  //   orderid: String,
  //   watermark: Boolean,
  //   forwho: String
  // },
  data() {
    return {
      washingShopList: [],
      orderid: "",
      wnfShop: {},
      shopInfo:{},
      signnextpage: false,
      thepage: 1,
      // shopInfo: {},


    };
  },

   computed: {
    //  *** BEGIN : 2020-04-06 : TAX INFO
    taxInfo(){
      return this.$store.getters["shop/tax"];
    },
    wnfOrder(){
      return this.$store.getters["wnfOrder/anOrder"];
    }
  },

  async mounted() {
    // this.theLogo = this.$store.state.defaultStore + "logo.png";
    this.orderHeaderId = this.orderid;
    this.orderid  = this.$route.params.wnforderid;
    // console.log(" this.orderid  >>> ",this.orderid );


     var params = {
   wnfOrderId : this.orderid
 };

 await this.$store.dispatch("wnfOrder/anOrder",params);


 this.shopinfo = await this.loadShopInfo();
   //*** Load WASHING SHOP INFO */

    var shopRes = null;

    try {
      shopRes = await this.$http.post(
        this.$store.state.apiURL + "/api/v2/orderheader/clean-fix/getWnfShop",
        {},
        this.$store.state.auth.apiHeader
      );


      this.washingShopList = shopRes.data.itemList;
      this.$store.commit("washingList/SET_WNFSHOP",this.washingShopList);

      console.log("afterlad this.wnfOrder",this.wnfOrder);

      var list = this.washingShopList.filter(x => x.wnfShopId === this.wnfOrder.header.wnfShopId);
      if(list.length > 0){
        this.wnfShop = list[0];
         console.log("afterlad this.wnfShop",this.wnfShop);

      }else{
        this.wnfShop = {};
      }

    } catch (error) {
      shopRes = null;

    }
  },
  methods: {

    checkOrder(text){

      if(text === null || text === undefined || text === ""){
        return "ไม่มีข้อมูลออเดอร์";
      }else{
        return text;
      }

    },
    async loadShopInfo() {
      var res = null;
      try {
        res = await this.$http.get(
          this.$store.state.apiURL + "/api/shopinfo",
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }

      return res.data;
    },

    getNameWnfShop(id){

    if(this.washingShopList.length > 0){
      var list = this.washingShopList.filter(x => x.wnfShopId === id);
      if(list.length > 0) return list[0].wnfShopName
    }
    return "ไม่ระบุ"
  },

    getRemoved(ind) {
      return this.detail[ind].flag1;
    },
    addlooplist(displaylist) {
      // var page;
      const first = 4;
      const lastpage = 4;
      const eachpage = 7;
      var pageNum = 1;
      const list_length = displaylist.length;
      // const list_length = 45;

      // if (list_length > first && list_length <= first + eachpage) {
      //   page = 2;
      // } else if (list_length > first + eachpage) {
      //   page = Math.ceil((list_length - first) / eachpage);
      // }

      var alllist = [];
      var list1 = [];
      // var list2 = [];
      // var list3 = [];

      let first_length = 0;
      var second = false;
      if (list_length <= first) {
        first_length = list_length;
        this.thepage = 1;
      } else if (list_length > first && list_length <= eachpage) {
        first_length = list_length;
        this.thepage = 2;
        this.signnextpage = true;
      } else {
        first_length = eachpage;
        // set ให้จำนวนหน้าแรกเท่ากับ eachpage = 20
        pageNum = Math.floor(list_length / eachpage);
        // set last number มากกว่า จำนวนหน้าสุดท้ายที่ set ไว้ = 12 ให้ย้ายลายเซ็นไปไว้อีกหน้า

        if (list_length - pageNum * eachpage <= 0) {
          this.lastPageNum = eachpage;
          this.thepage = pageNum;
        } else {
          this.lastPageNum = list_length - pageNum * eachpage;
          this.thepage = pageNum + 1;
        }

        if (this.lastPageNum > lastpage) {
          this.signnextpage = true;
        }

        second = true;
      }

      // console.log("The PAGEESSSSSS >>>> ", this.thepage);

      for (var i = 0; i < first_length; i++) {
        list1[i] = displaylist[i];
      }

      alllist.push(list1);

      if (second === true) {
        for (var b = 1; b < this.thepage; b++) {
          var listg = [];
          var pagenumber = eachpage;
          if (b === this.thepage - 1) {
            pagenumber = this.lastPageNum;
          }
          for (var j = 0; j < pagenumber; j++) {
            listg[j] = displaylist[j + b * eachpage];
          }
          alllist.push(listg);
        }

        // console.log("###### second === true alllist >>>>>>>>>>> ", alllist);
        // console.log(
        //   "###### second === true first_length >>>>>>>>>>> ",
        //   first_length
        // );
      }

      // this.looplist = alllist;

      // console.log("list_length >>>>> ", list_length);
      // console.log("LIST 1 >>>>> ", list1);
      // console.log("LIST 2 >>>>> ", list2);
      // console.log("ALL LIST  >>>>> ", this.looplist);
      return alllist;
    },


   theLastTotalPrice() {
      var rd = isNaN(Number(this.rentalDiscount))
        ? 0
        : Number(this.rentalDiscount);

      var pd = isNaN(Number(this.promotionDiscount))
        ? 0
        : Number(this.promotionDiscount);

      var total = isNaN(Number(this.rentalPriceTotal))
        ? 0
        : Number(this.rentalPriceTotal);

      // console.log("total : ", total, " pd : ", pd, " rd : ", rd);

      if(this.taxInfo.vatSystem === false)
        return total - (pd + rd);
      else{
        return this.rentalPriceTotal_final(this.rentalPriceTotal, this.rentalDiscount);
      }

    },
    theLastBail() {
      var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);

      var total = isNaN(Number(this.bailTotal)) ? 0 : Number(this.bailTotal);
      return total - bd;
    },
    conditionImage() {
      return this.$store.state.defaultStore + "condition.png";
    },
    alreadycheckedImage() {
      return this.$store.state.defaultStore + "alreadychecked.png";
    },
    rentalImage() {
      return this.$store.state.defaultStore + "rental.png";
    },
    bailImage() {
      return this.$store.state.defaultStore + "bail.png";
    },
    returnImage() {
      return this.$store.state.defaultStore + "return.png";
    },

    backtorental() {
      this.$router.push("/backoffice/rental/" + this.orderHeaderId);
    },

    theLogo() {
      // console.log('this.shopinfo.logo >>> ',this.shopinfo.logo);
      if (
        this.shopinfo.logo === undefined ||
        this.shopinfo.logo === "" ||
        this.shopinfo.logo === null
      ) {
        return this.$store.state.defaultStore + "logo.png";
      } else {
        return this.$store.state.defaultStore  + this.shopinfo.logo;
      }
    },

    // async loadProduct() {
    //   var response;
    //   try {
    //     response = await this.$http.get(
    //       this.$store.state.apiURL + "/api/productitem",
    //       this.$store.state.auth.apiHeader
    //     );
    //     if (response.status === 200) {
    //       this.branchStock = await response.data;
    //     }
    //   } catch (error) {
    //     this.noticeError(error);
    //   }

    //   return await response.data;
    // },
    // async loadStock(branchId) {
    //   var response;
    //   try {
    //     response = await this.$http.get(
    //       this.$store.state.apiURL + "/api/productitem/branch/" + branchId,
    //       this.$store.state.auth.apiHeader
    //     );
    //     if (response.status === 200) {
    //       this.branchStock = await response.data;
    //     }
    //   } catch (error) {
    //     this.noticeError(error);
    //     // console.log("error", error);
    //   }

    //   return await response.data;
    // },
    // >>>>>> เราต้องการ this.displayItems
    async comeback(orderid) {

      var list = [];
      // this.orderHeaderId = this.$route.params.orderid;
      // this.currentUser = JSON.parse(localStorage.getItem("username"));

      var resp = null;
      try {
        resp = await this.$http.post(
          this.$store.state.apiURL + "/api/orderheader/receipt-info/" + orderid,
          {},
          this.$store.state.auth.apiHeader
        );
        this.orderInfo = resp.data;

        this.currentBranchId =  this.orderInfo.orderHeader.branchId;

        this.orderNumber =  this.orderInfo.orderHeader.orderNumber;
        this.customerName =  this.orderInfo.orderHeader.customerName;
        this.customerAddress =  this.orderInfo.orderHeader.customerAddress;
        this.customerPhone =  this.orderInfo.orderHeader.customerPhone;
        this.receivingMethod =  this.orderInfo.orderHeader.receivingMethod;
        this.startDate =  this.orderInfo.orderHeader.pickupDate;
        this.endDate =  this.orderInfo.orderHeader.returnDate;
        this.rentalPriceTotal =  this.orderInfo.orderHeader.rentalPriceTotal;
        this.bailTotal =  this.orderInfo.orderHeader.bailTotal;
        this.grandTotal =  this.orderInfo.orderHeader.grandTotal;
        this.rentalDiscount =  this.orderInfo.orderHeader.rentalDiscount;
        this.bailDiscount =  this.orderInfo.orderHeader.bailDiscount;
        this.promotionDiscount =  this.orderInfo.orderHeader.promotionDiscount;
        this.orderStatus =  this.orderInfo.orderHeader.orderStatus;
        this.remark1 =  this.orderInfo.orderHeader.remark1;

        this.detail =  this.orderInfo.orderDetail;
        var dlist =  this.orderInfo.orderDetail;


        for (var d = 0; d < dlist.length; d++) {
          var theItm = {};
          theItm  = Object.assign({}, dlist[d])
          theItm.removed =  dlist[d].flag1;
          this.displayItems.push(theItm);
          this.orders.push(theItm);
          list.push(theItm);
        }

        this.shopInfo = this.orderInfo.shopInfo;
        this.shopinfo = this.orderInfo.shopInfo;

        this.aBranch = this.orderInfo.branch;


      } catch (err) {
        this.$swal({
          icon: 'warning',
          title: 'ไม่สามารถรับข้อมูลได้',
        });
      }



      //    var order = await this.loadOrder(orderid);
      // var dlist = await this.loadDetail(orderid);
      // this.detail =  dlist;

      // this.orderNumber = order.orderNumber;



      // this.currentBranchId =  order.branchId;

      // await this.init(this.currentBranchId);


      // this.orderNumber =  order.orderNumber;
      // this.customerName =  order.customerName;
      // this.customerAddress =  order.customerAddress;
      // this.customerPhone =  order.customerPhone;
      // this.receivingMethod =  order.receivingMethod;
      // this.startDate =  order.pickupDate;
      // this.endDate =  order.returnDate;
      // this.rentalPriceTotal =  order.rentalPriceTotal;
      // this.bailTotal =  order.bailTotal;
      // this.grandTotal =  order.grandTotal;
      // this.rentalDiscount =  order.rentalDiscount;
      // this.bailDiscount =  order.bailDiscount;
      // this.promotionDiscount =  order.promotionDiscount;
      // this.orderStatus =  order.orderStatus;
      // this.remark1 =  order.remark1;

      // this.aBranch = await this.getBranchShare(this.currentBranchId);

      // // LOAD DETAIL
      // // console.log('####### COMEBACK >>>> ',dlist);

      // var list = [];
      // for (var d = 0; d < dlist.length; d++) {
      //   var anItem =  this.itemsInBranch.find(
      //     x =>
      //         // x.productItemId === dlist[d].productItemId
      //         // ||
      //         x.code === dlist[d].code

      //   );



      //    anItem.reservedQuantity = dlist[d].reservedQuantity;

      //   anItem.removed =  dlist[d].flag1;


      //   var theItm  = Object.assign({}, anItem)




      //    this.displayItems.push(theItm);
      //    this.orders.push(theItm);
      //    list.push(theItm);
      // }

      return list;
    },

    async loadOrder(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderheader/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    async loadDetail(orderid) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/orderdetail/" + orderid,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
      }

      return await response.data;
    },

    totalRental: function() {
      var total = 0;
      for (var i = 0; i < this.orders.length; i++) {
        total += this.orders[i].rentalPrice;
      }
      return total;
    },
    // goback
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    setFocus: function() {
      // Note, you need to add a ref="search" attribute to your input.
      // this.$refs.addcart.$el.focus();
    },

    lineTotal: function(rentalPrice, bail) {
      if (!Number.isFinite(rentalPrice)) rentalPrice = 0;
      if (!Number.isFinite(bail)) rentalPrice = 0;
      return rentalPrice + bail;
    },
    removeItem: function(indextr) {
      this.orders[indextr].reservedQuantity = 1;
      this.orders.splice(indextr, 1);
      // console.log(this.orders);
    },
    setSelect: function() {
      event.target.setSelectionRange(0, this.addtocart.length);
    }
  },
  async created() {
    // var rp = this.$route.params.orderno;
    // alert(rp);

     await this.$store.dispatch(
      "productGroup/getAllInfo"
    );

    this.categories = this.$store.getters["productGroup/categories"];
    this.textures = this.$store.getters["productGroup/textures"];
    this.colors = this.$store.getters["productGroup/colors"];
    this.sizes = this.$store.getters["productGroup/sizes"];



// INITIALIZE DATA
 await this.$store.dispatch('shop/fetch');



//  console.log(" wnfOrder >>> ",this.wnfOrder);
    // // 1. ORDER HEADER
    // this.orderStatus = 0; // 0: darft, 1: saved, 2: waiting for receiving, 3: waiting for returning, 4: waiting for money back, 5: closed

    // // 2. CUSTOMER
    // this.customerInfo = "";
    // this.customerName = "";
    // this.customerAddress = "";
    // this.customerPhone = "";

    // // 3. DELIVERY
    // this.howtoreceive = "มารับเอง"; // 1 :มารับเอง, 2: ผ่านเมสเซนเจอร์, 3: ผ่าน EMS / Kerry
    // this.receivingDate = null;
    // this.returnDate = null;
    // this.addtocart = "";

    // // 4. MONETARY

    // // this.bailDiscount = 300;
    // // this.rentalDiscount = 100;
    // this.promotionDiscount = 0;

    // this.rentalPriceTotal = 0;
    // this.bailTotal = 0;
    // this.grandTotal = 0;

    // this.orderHeaderId = this.$route.params.orderid;
    // this.currentUser = JSON.parse(localStorage.getItem("username"));

    // // BEGIN >>>>>>> INITAILZE ITEMS IN BRANCH : params : {thisBranch} >>>>>>>>>

    //  await this.comeback(this.orderHeaderId);

    // this.looplist = await this.addlooplist(this.orders);


    // // for (var i = 0; i < this.orders.length; i++) {
    // //   this.rentalPriceTotal =
    // //     this.rentalPriceTotal +
    // //     this.orders[i].rentalPrice * this.orders[i].reservedQuantity;

    // //   this.bailTotal =
    // //     this.bailTotal + this.orders[i].bail * this.orders[i].reservedQuantity;
    // // }
    // // this.grandTotal = this.grandTotal + this.rentalPriceTotal + this.bailTotal;

    // // console.log("this.grandTotal >>> ", this.grandTotal);
  },
  components: {
    Prism,
    Datepicker,
    VueBarcode
  },


  watch: {
    // // addtocart: function(newvalue) {
    // //   var newItem = this.itemsInStock.find(function(ele) {
    // //     if (ele.itemName === newvalue) {
    // //       return ele;
    // //     }
    // //   });
    // //   if (newItem) {
    // //     event.target.setSelectionRange(0, this.addtocart.length);
    // //     var foundindex = this.displayItems.findIndex(
    // //       x => x.itemName === newItem.itemName
    // //     );
    // //     if (foundindex < 0) this.displayItems.push(newItem);
    // //     else {
    // //       this.displayItems[foundindex].reservedQuantity++;
    // //     }
    // //     // setTimeout(function() {
    // //     //   this.addtocart = "";
    // //     // }, 2000);
    // //     this.addtocart = "";
    // //   }
    // },
    // orders: {
    //   handler() {
    //     this.rentalPriceTotal = 0;
    //     this.bailTotal = 0;
    //     this.grandTotal = 0;
    //     for (var i = 0; i < this.orders.length; i++) {
    //       this.rentalPriceTotal =
    //         this.rentalPriceTotal +
    //         this.orders[i].rentalPrice * this.orders[i].reservedQuantity;
    //       this.bailTotal =
    //         this.bailTotal +
    //         this.orders[i].rentalPrice * this.orders[i].reservedQuantity;
    //     }
    //     this.grandTotal =
    //       this.grandTotal + this.rentalPriceTotal + this.bailTotal;
    //     console.log("this.grandTotal >>> ", this.grandTotal);
    //   },
    //   deep: true
    // }
  }
};
</script>

<style>

.page {
  width: 210mm;
  min-height: 291mm;
  padding: 4mm 4mm 2mm;
  margin: 0 auto;
  border: 1px #d3d3d3 solid;
  /* border-radius: 5px; */
  background: white;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
@page {
  size: A4;
  margin: 0;
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }

  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  .page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}


body {
  background-color: white !important;
}
input {
  width: 100%;
}
.theLabel {
  font-weight: bold;
  font-size: 12px;
}

.vs-col {
  flex-wrap: wrap;
}
.row-form {
  margin-bottom: 30px;
}
.form-label {
  font-weight: bold;
}

.grand-total {
  font-weight: bold;
  font-family: "SukhumvitSet-Medium";
  font-size: 14px;
}
.grand-total-discount {
  font-family: "SukhumvitSet-Medium";
  font-size: 12px;
}
.table-font {
  font-family: "SukhumvitSet-Medium";
  font-size: 12px;
}

.the-black {
  color: #494949;
}
.forprint {
  font-size: 13px;
}

.the-blue {
  color: #4a90e2;
}
.the-green {
  color: #7ed321;
}
.the-red {
  color: #e95455;
}
.vx-card {
  padding-top: 0 !important;
  box-shadow: none !important;
}

.vx-card__body {
  padding: 0 !important;
}

.vue-barcode-element {
  width: 200px !important;
  height: 70px;
}

.vs-table--tbody-table .tr-values td {
  padding: 10px;
  padding-bottom: 0;
  padding-top: 5px;
  overflow: hidden;
}

.customer_label {
  position: absolute;
  vertical-align: middle;
  text-align: center;
  width: 100px;
  /*height: 40px; */
  background-color: #549c04;
  color: white;
  margin-left: 20px;
  padding-top: 5px;
  font-family: "SukhumvitSet-Medium";
}

.staff_label {
  position: absolute;
  vertical-align: middle;
  text-align: center;
  width: 100px;
  /*height: 40px;*/
  background-color: #e02a6a;
  color: white;
  margin-left: 20px;
  padding-top: 5px;
  font-family: "SukhumvitSet-Medium";
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}
</style>
